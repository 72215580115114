import {
  Avatar,
  Button,
  Byline,
  Checkbox,
  IconButton,
  IconEditLine,
  Link,
  Table,
  Spinner,
  Tabs,
  SimpleSelect,
} from "@instructure/ui";
import { useEffect, useState } from "react";
import {
  createStrapiBluePrintCourse,
  getInstructors,
  getListModulesAPI,
  getLmsBluePrintCourse,
  getLmsRootAccountId,
  getTerms,
  getTopicsInBluePrintCourse,
  updateTopicComplete,
  updateTopic,
  getBluePrintCourse,
  getCurriculaAPI,
  getContentsAPI,
} from "../../utils/API";
import SingleSelect from "../components/singleSelect";
import ModuleModal from "./components/moduleModal";
import Curricula from "./components/curricula";

const userInfo = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : null;

const ModulePage = () => {
  const [dataForCreate, setDataForCreate] = useState({
    name: "",
    modules: [],
    users: [],
    blueprint_course: null,
    lesson_plan: null,
  });
  const [dataForUpdate, setDataForUpdate] = useState(null);
  const [mode, setMode] = useState("CREATE");
  const [isShowModal, setIsShowModal] = useState(false);
  const [termList, setTermList] = useState([]);
  const [bluePrintList, setBluePrintList] = useState([]);
  const [instructorList, setInstructorList] = useState([]);
  const [topicList, setTopicList] = useState([]);

  const [domain, setDomain] = useState(null);
  const [rootAccountId, setRootAccountId] = useState(null);
  const [selectedTermId, setSelectedTermId] = useState(null);
  const [selectedBluePrintCourseId, setSelectedBluePrintCourseId] =
    useState(null);
  const [bottomBluePrintId, setBottomBluePrintId] = useState(null);
  const [moduleList, setModuleList] = useState([]);

  const [isCourse, setIsCourse] = useState(false);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [curricula, setCurricula] = useState([]);
  const [contents, setContents] = useState([]);
  const [seltecOptions, setSeltecOptions] = useState([]);

  useEffect(() => {
    getListTerms();
  }, []);

  useEffect(() => {
    if (selectedTermId && selectedTermId !== null) {
      getBaseBluePrintCourse();
    }
  }, [selectedTermId]);

  useEffect(() => {
    if (selectedBluePrintCourseId) {
      getListInstructors();
      getListModules();
      getCurrilula();
    }
  }, [selectedBluePrintCourseId]);

  useEffect(()=>{
    renderSelectOptionCurricula();
  }, [contents])

  useEffect(() => {
    if (bluePrintList?.length > 0 && selectedBluePrintCourseId) {
      const selectedBP = bluePrintList?.find(
        (i) => i?.id === selectedBluePrintCourseId
      )?.blueprint_course_id;
      setBottomBluePrintId(selectedBP);
      filterTopics();
    }
  }, [bluePrintList, selectedBluePrintCourseId]);

  const getBaseBluePrintCourse = async () => {
    try {
      const res = await getLmsBluePrintCourse(selectedTermId);

      if (res.status === 200) {
        const data = res.data;

        let tmp = [];
        const promises = [];

        data.forEach((element) => {
          promises.push(getBluePrinCourse(element, tmp));
        });

        Promise.all(promises).then(() => {
          setBluePrintList([...tmp]);
        });
      }
    } catch (error) {}
  };

  const getBluePrinCourse = async (element, tmp) => {
    let item = {
      id: element.id,
      label: element.name,
      blueprint_course_id: null,
    };
    const res2 = await syncBluePrintToStrapi(element);

    if (res2.status === 200) {
      item.blueprint_course_id = res2.data.id;
    }
    tmp.push(item);
  };

  const syncBluePrintToStrapi = async (item) => {
    try {
      const params = {
        name: item.name,
        canvas_course_id: item.id.toString(),
        canvas_root_account_id: item.root_account_id.toString(),
      };

      return await createStrapiBluePrintCourse(params);
    } catch (error) {
      console.log("syncBluePrintToStrapi error:::", error);
    }
  };

  const filterTopics = async () => {
    try {
      const blueprintCourse = bluePrintList.find(
        (x) => x.id === selectedBluePrintCourseId
      );
      if (blueprintCourse && blueprintCourse?.blueprint_course_id) {
        const res = await getTopicsInBluePrintCourse(
          blueprintCourse?.blueprint_course_id
        );

        if (res.status === 200) {
          const data = res.data;
          let arr = [];

          data.forEach((element) => {
            let item = element;
            item[
              "link"
            ] = `${domain}/courses/${selectedBluePrintCourseId}/modules/${element?.modules?.[0]?.id}`;

            arr.push(item);
          });

          setTopicList(arr);
        }
      }
    } catch (error) {}
  };

  const getCurrilula = async () => {
    try {
      const blueprintCourse = bluePrintList.find(
        (x) => x.id === selectedBluePrintCourseId
      );
      if (blueprintCourse && blueprintCourse?.blueprint_course_id) {
        const res = await getCurriculaAPI(blueprintCourse.blueprint_course_id);
        
        setCurricula(res.data);
        const resp = await getContentsAPI(blueprintCourse.blueprint_course_id, res.data.id);
        setContents(resp.data)
      }
    } catch (error) {}
  }

  const getSubContents = (content, indent, options) => {
    const subContents = contents.filter(cont => cont.parent_content_ids.includes(content.id))
    const indentation = '\u00A0'.repeat(indent * 4);
    if (subContents.length > 0) {
      options.push(
        <option
            id={content.id}
            value={content.id}
            isDisabled={isCourse}
          >
          {`${indentation}${content.name}`}
        </option>)
      subContents.map(cont => {
        return getSubContents(cont, indent + 1, options)
      })
    } else {
      options.push(
        <option
          id={content.id}
          value={content.id}
          isDisabled={isCourse}
        >
        {`${indentation}${content.name}`}
        </option>)
      return
    }
  }
  
  const renderSelectOptionCurricula = (row) => {
    let options = [];
    contents.filter(content => content.parent_content_ids == "0").map(content => {
      getSubContents(content, 0, options)
    })
    setSeltecOptions(options)
  }

  const handleSelectOptionCurricula = async (value, id) => {
    try {
      if (value == "empty") {
        const res = await updateTopic(id, { content: null });

        if (res.status === 200) filterTopics();
      } else {
        const res = await updateTopic(id, { content: value });

        if (res.status === 200) filterTopics();
      }
     
    } catch (error) {}
  }

  const handleSetShowCreateModal = () => {
    setMode("CREATE");
    setIsShowModal(true);
  };

  const handleShowUpdateModal = (row) => {
    setDataForUpdate(row);
    setMode("UPDATE");
    setIsShowModal(true);
  };

  const handleChangeIsComplete = async (e, id) => {
    const checked = e.target.checked;

    try {
      const res = await updateTopicComplete(id, { is_completed: checked });

      if (res.status === 200) filterTopics();
    } catch (error) {}
  };

  const handleChangeOnline = async (e, id) => {
    const checked = e.target.checked;
    try {
      const res = await updateTopic(id, { is_online: checked });

      if (res.status === 200) filterTopics();
    } catch (error) {}
  }

  const handleUpdateResult = async (e, id) => {
    try {
      const res = await updateTopic(id, { result: e });

      if (res.status === 200) filterTopics();
    } catch (error) {}
  }

  const getListInstructors = async () => {
    try {
      const res = await getInstructors(selectedBluePrintCourseId);

      if (res.status === 200) {
        const data = res.data;

        let arr = [];

        data.forEach((element) => {
          arr.push({
            id: element?.user?.id,
            label: element?.user?.name,
            sis_user_id: element?.user?.sis_user_id,
          });
        });

        setInstructorList(arr);
      }
    } catch (error) {}
  };

  const getListTerms = async () => {
    try {
      const res = await getLmsRootAccountId();

      if (res.status === 200) {
        const rootAccountId = res.data.root_account_id;
        if ( 'custom_canvas_course_id' in res.data.data) {
          setRootAccountId(rootAccountId);
          setDomain(res.data?.domain_lms);
          setIsCourse(true);
          const resp = await getBluePrintCourse(res.data.data.custom_canvas_course_id)
          const courses = resp.data.data
          if (courses.length > 0) {
            setSelectedBluePrintCourseId(courses[0].canvas_course_id)
            setBluePrintList([{
              id: courses[0].canvas_course_id,
              name: courses[0].name,
              blueprint_course_id: courses[0].id
            }])
          }
          return
        }
        setRootAccountId(rootAccountId);
        setDomain(res.data?.domain_lms);
        if (rootAccountId) {
          const res2 = await getTerms(rootAccountId);

          if (res2.status === 200) {
            const data = res2.data?.enrollment_terms;
            let arr = [];

            data.forEach((element) => {
              arr.push({
                id: element?.id,
                label: element?.name,
              });
            });

            setTermList(arr);
          }
        }
      }
    } catch (error) {}
  };

  const getListModules = async () => {
    try {
      const res = await getListModulesAPI(selectedBluePrintCourseId);
      if (res.status === 200) {
        setModuleList(res.data);
      }
    } catch (error) {}
  };

  const onCreateCallback = () => {
    filterTopics();
    getListModules();
  };

  const handleTabChange = (event, { index, id }) => {
    setSelectedIndex(index)
  }

  return (
    <div className="p-8 w-full">
      <h1 className="text-3xl">Đăng ký chủ đề</h1>
      {isCourse == true ? <></> : <div className="my-2 flex flex-row space-x-4 mt-4">
        <SingleSelect
          labelName={"Kỳ học"}
          dataSource={termList}
          className="basis-1/2"
          selectedValue={selectedTermId}
          setSelectedValue={setSelectedTermId}
        />
        <SingleSelect
          labelName={"Khóa học mẫu"}
          dataSource={bluePrintList}
          className="basis-1/2"
          selectedValue={selectedBluePrintCourseId}
          setSelectedValue={setSelectedBluePrintCourseId}
        />
      </div>}
      

      <div className="border rounded p-4">
        {selectedBluePrintCourseId != null ?
        <Tabs
         margin="large auto"
         padding="medium"
         onRequestTabChange={handleTabChange}
        >
         <Tabs.Panel
           id="topic"
           renderTitle="Chủ đề"
           isSelected={selectedIndex === 0}
         >
           {userInfo?.role?.type === "account_admin" && (
             <Button
               color="primary"
               margin="small"
               onClick={handleSetShowCreateModal}
             >
               Thêm chủ đề
             </Button>
           )}

           <div>
             <Table caption="Danh sách chủ đề" layout={"auto"} hover={true}>
               <Table.Head>
                 <Table.Row>
                   <Table.ColHeader id="ID">ID</Table.ColHeader>
                   <Table.ColHeader id="Title">Tên chủ đề</Table.ColHeader>
                   <Table.ColHeader id="Module">Module</Table.ColHeader>
                   <Table.ColHeader id="Instructor">
                     Người được phân công
                   </Table.ColHeader>
                   <Table.ColHeader id="Instructor">
                     Liên kết chương trình học
                   </Table.ColHeader>
                   <Table.ColHeader id="type">Dạy trực tuyến</Table.ColHeader>
                   <Table.ColHeader id="Done">Hoàn thành</Table.ColHeader>
                   <Table.ColHeader id="Done">Xếp loại</Table.ColHeader>
                   <Table.ColHeader id="Url">Link điều hướng</Table.ColHeader>
                   <Table.ColHeader id="Action"></Table.ColHeader>
                 </Table.Row>
               </Table.Head>
               <Table.Body>
                 {topicList?.length === 0 && (
                   <Spinner
                     renderTitle="Loading"
                     size="small"
                     margin="0 0 0 medium"
                   />
                 )}
                 {(topicList || []).map((row) => {
                   return (
                     <Table.Row key={row.id}>
                       <Table.RowHeader>{row.id}</Table.RowHeader>
                       <Table.Cell>{row.name}</Table.Cell>
                       <Table.Cell>
                         <div className="flex flex-col space-y-4">
                           {row.modules.map((item) => (
                             <span>{item?.name}</span>
                           ))}
                         </div>
                       </Table.Cell>
                       <Table.Cell>
                         <div className="space-y-4">
                           {row.users.map((item) => (
                             <Byline description={item?.name}>
                               <Avatar name={item?.name} size="x-small"/>
                             </Byline>
                           ))}
                         </div>
                       </Table.Cell>
                       <Table.Cell>
                        <select
                          value={row?.content?.id || "empty"}
                          onChange={(e) => handleSelectOptionCurricula(e.target.value, row.id)}
                        >
                          <option
                              id={"empty"}
                              value={"empty"}
                              isDisabled={isCourse}
                            >
                              --
                          </option>
                          {seltecOptions.map(e => {
                            return e
                          })}
                        </select>
                       </Table.Cell>
                       <Table.Cell>
                        <Checkbox
                           value="online_teaching"
                           checked={row?.is_online}
                           disabled={isCourse}
                           onChange={(e) => handleChangeOnline(e, row.id)}
                         />
                       </Table.Cell>
                       <Table.Cell>
                         <Checkbox
                           value="is_completed"
                           checked={row?.is_completed}
                           onChange={(e) => handleChangeIsComplete(e, row.id)}
                         />
                       </Table.Cell>
                       <Table.Cell>
                        <SimpleSelect
                            value={row.result}
                            onChange={(e, { id, value }) => handleUpdateResult(value, row.id)}
                          >
                             <SimpleSelect.Option
                                id={"emply"}
                                value={"emply"}
                                isDisabled={isCourse}
                              >
                                --
                            </SimpleSelect.Option>
                            <SimpleSelect.Option
                                id={"tot"}
                                value={"tot"}
                                isDisabled={isCourse}
                              >
                                Tốt
                            </SimpleSelect.Option>
                            <SimpleSelect.Option
                                id={"dat"}
                                value={"dat"}
                                isDisabled={isCourse}
                              >
                                Đạt
                            </SimpleSelect.Option>
                            <SimpleSelect.Option
                                id={"chua_dat"}
                                value={"chua_dat"}
                                isDisabled={isCourse}
                              >
                                Chưa đạt
                            </SimpleSelect.Option>
                          </SimpleSelect>
                       </Table.Cell>
                       <Table.Cell>
                         {/* <Link href={row.link}>Link</Link> */}
                         <a
                           href={row.link}
                           target="_blank"
                           className="text-blue-600"
                         >
                           Link
                         </a>
                       </Table.Cell>
                       <Table.Cell>
                         <IconButton
                           screenReaderLabel="Edit"
                           onClick={() => handleShowUpdateModal(row)}
                         >
                           <IconEditLine />
                         </IconButton>
                       </Table.Cell>
                     </Table.Row>
                   );
                 })}
               </Table.Body>
             </Table>
           </div>
         </Tabs.Panel>
         <Tabs.Panel id="curriculum" renderTitle="Chương trình học" isSelected={selectedIndex === 1}>
           <Curricula selectedBluePrintCourse={bluePrintList?.find(e => e.id == selectedBluePrintCourseId)}/>
         </Tabs.Panel>
       </Tabs>
        : 
        isCourse ? <h5 > Khóa học này không phải là khóa học mẫu hoặc chưa được kích hoạt, vui lòng liên hệ quản trị viên của Tổ/Nhà trường để được hỗ trợ</h5> 
        : <h4 className="text-3xl">Vui lòng chọn kỳ học và khóa học mẫu</h4>
        }
       
      </div>

      <ModuleModal
        isShowModal={isShowModal}
        setIsShowModal={setIsShowModal}
        mode={mode}
        data={mode === "CREATE" ? dataForCreate : dataForUpdate}
        instructorList={instructorList}
        moduleList={moduleList}
        selectedBluePrintCourse={bluePrintList?.find(
          (i) => i?.id === selectedBluePrintCourseId
        )}
        bottomBluePrintId={bottomBluePrintId}
        reloadList={onCreateCallback}
      />
    </div>
  );
};

export default ModulePage;
