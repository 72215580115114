import { Button, InstUISettingsProvider, canvas } from "@instructure/ui";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import "./App.css";
import ModulePage from "./page/modulePage";
import ReportPage from "./page/reportPage";
import Sidebar from "./layout/Sidebar";
import Auth from "./page/components/Auth";

function App() {
  return (
    <InstUISettingsProvider theme={canvas}>
      <BrowserRouter>
        <div className="flex">
          <Sidebar />
          <Routes>
            <Route path="/" element={<ModulePage />} />
            <Route path="/lms/auth" element={<Auth />} />
            <Route path="/pages/403" element={<h1> Unauthorized </h1>} />
            <Route path="/module-page" element={<ModulePage />} />
            <Route index path="/report-page" element={<ReportPage />} />
          </Routes>
        </div>
      </BrowserRouter>
    </InstUISettingsProvider>
  );
}

export default App;
