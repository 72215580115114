import {
  Button,
  CloseButton,
  Heading,
  Modal,
  TextInput,
} from "@instructure/ui";
import { useEffect, useState } from "react";
import { createTopic, updateTopic, deleteTopic } from "../../../../utils/API";
import MultipleSelect from "../../../components/multiSelect";

const ModuleModal = ({
  isShowModal,
  setIsShowModal,
  mode = "CREATE",
  data,
  instructorList,
  moduleList,
  selectedBluePrintCourse,
  bottomBluePrintId,
  reloadList,
}) => {
  const [name, setName] = useState(null);
  const [selectedModuleIds, setSelectedModuleIds] = useState([]);
  const [selectedInstructorIds, setSelectedInstructorIds] = useState([]);

  useEffect(() => {
    if (mode === "UPDATE" && data) {
      setName(data?.name);
      setSelectedModuleIds(data?.modules?.map((i) => i.id));
      setSelectedInstructorIds(data?.users?.map((i) => i.id));
    }
    if (mode === "CREATE") {
      setName("");
      setSelectedModuleIds([]);
      setSelectedInstructorIds([]);
    }
  }, [mode, data]);

  const handleCloseModal = () => {
    setIsShowModal(false);
  };

  const renderCloseButton = () => {
    return (
      <CloseButton
        placement="end"
        offset="small"
        onClick={handleCloseModal}
        screenReaderLabel="Close"
      />
    );
  };

  const handleChangeName = (event, value) => {
    setName(value);
  };

  const handleDelete = async () => {
    try {
      const res = await deleteTopic(data?.id, selectedBluePrintCourse?.id);
      if (res.status === 200) {
        handleCloseModal();
        reloadList?.();
      }
    } catch (e) {}
  }

  const handleCreate = async () => {
    try {
      const users = instructorList
        ?.filter((i) => selectedInstructorIds?.includes(i?.id))
        ?.map((i) => ({
          id: i?.id,
          name: i?.label,
          sis_user_id: i?.sis_user_id,
        }));
      const res = await createTopic({
        name: name || "",
        modules: [
          {
            id: selectedBluePrintCourse?.id || "",
            name: selectedBluePrintCourse?.label || "",
          },
        ],
        users: users,
        blueprint_course: {
          id: bottomBluePrintId || "",
        },
        lesson_plan: null,
      });
      if (res.status === 200) {
        handleCloseModal();
        reloadList?.();
      }
    } catch (error) {}
  };

  const handleUpdate = async () => {
    try {
      const users = instructorList
        ?.filter((i) => selectedInstructorIds?.includes(i?.id))
        ?.map((i) => ({
          id: i?.id,
          name: i?.label,
          sis_user_id: i?.sis_user_id,
        }));
      const modules = moduleList
        ?.filter((i) => selectedModuleIds?.includes(i?.id))
        ?.map((i) => ({ id: i?.id, name: i?.name }));
      const res = await updateTopic(data?.id, {
        name: name || "",
        modules: modules,
        users: users,
        blueprint_course: {
          id: bottomBluePrintId || "",
        },
        lesson_plan: null,
      });
      if (res.status === 200) {
        handleCloseModal();
        reloadList?.();
      }
    } catch (error) {}
  };

  return (
    <Modal
      as="form"
      open={isShowModal}
      onDismiss={handleCloseModal}
      onSubmit={() => {}}
      size="medium"
      label={mode === "CREATE" ? "Thêm chủ đề" : "Chỉnh sửa chủ đề"}
      shouldCloseOnDocumentClick
    >
      <Modal.Header>
        {renderCloseButton}
        <Heading>
          {mode === "CREATE" ? "Thêm chủ đề" : "Chỉnh sửa chủ đề"}
        </Heading>
      </Modal.Header>
      <Modal.Body>
        <TextInput
          renderLabel="Tên chủ đề"
          placeholder="Nhập tên chủ đề"
          value={name}
          onChange={handleChangeName}
        />
        <br />
        <MultipleSelect
          title="Người được phân công"
          dataSource={instructorList}
          selectedIds={selectedInstructorIds}
          setSelectedIds={setSelectedInstructorIds}
        />
        <br />
        {mode === "UPDATE" && (
          <MultipleSelect
            title="Module"
            dataSource={moduleList}
            selectedIds={selectedModuleIds}
            setSelectedIds={setSelectedModuleIds}
            textField="name"
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleCloseModal} margin="0 x-small 0 0">
          Close
        </Button>
        {mode === "CREATE" ? <></> : <Button color="danger" onClick={handleDelete} margin="0 x-small 0 0">
          Xóa
        </Button>}
        <Button
          color="primary"
          onClick={mode === "CREATE" ? handleCreate : handleUpdate}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModuleModal;
