import { Button, IconButton, IconEditLine, Table } from "@instructure/ui";
import { useState, useEffect} from "react";
import { getReport } from "../../utils/API";

const ReportPage = () => {
  const [dataList, setDataList] = useState([
    {
      title: "Tổ 1",
      percent: "80%",
    },
    {
      title: "Tổ 2",
      percent: "35%",
    },
    {
      title: "Tổ 3",
      percent: "70%",
    },
  ]);
  useEffect(() => {
    getReportAPI();
  }, []);
  
  const getReportAPI = async () => {
    const res = await getReport();
    setDataList(res.data)
  }

  const renderProgress = (item) => {
    const isOnline = item?.courses?.reduce((isOnline, course) => isOnline + (course.topics?.filter(e => e.is_online == true).length || 0), 0) || 0;
    const topic = item?.courses?.reduce((topics, course) => topics + (course.topics?.length || 0), 0) || 0;
    const topic_tot = item?.courses?.reduce((topic_tot, course) => topic_tot + (course.topics?.filter(e => e.result == "tot").length || 0), 0) || 0;
    const topic_dat = item?.courses?.reduce((topic_dat, course) => topic_dat + (course.topics?.filter(e => e.result == "dat").length || 0), 0) || 0;
    const topic_chua_dat = item?.courses?.reduce((topic_chua_dat, course) => topic_chua_dat + (course.topics?.filter(e => e.result == "chua_dat").length || 0), 0) || 0;
    const topic_empty = item?.courses?.reduce((topic_empty, course) => topic_empty + (course.topics?.filter(e => e.result == null || e.result == "empty").length || 0), 0) || 0;
    return (
      <Table.Row>
        <Table.RowHeader>{item?.title}</Table.RowHeader>
        <Table.Cell>{item?.percent}</Table.Cell>
        <Table.Cell>{item?.courses?.length || 0}</Table.Cell>
        <Table.Cell>{topic}</Table.Cell>
        <Table.Cell>{isOnline}</Table.Cell>
        <Table.Cell>{`${Number(((isOnline/topic) || 0).toFixed(2) * 100)} %`}</Table.Cell>

        <Table.Cell>{`${Number(((topic_tot/topic) || 0).toFixed(2) * 100)} %`}</Table.Cell>
        <Table.Cell>{`${Number(((topic_dat/topic) || 0).toFixed(2) * 100)} %`}</Table.Cell>
        <Table.Cell>{`${Number(((topic_chua_dat/topic) || 0).toFixed(2) * 100)} %`}</Table.Cell>
        <Table.Cell>{`${Number(((topic_empty/topic) || 0).toFixed(2) * 100)} %`}</Table.Cell>
      </Table.Row>
    );
  };

  return (
    <div className="p-8">
      <h1 className="text-3xl">Xem báo cáo</h1>

      <div>
        <div>
          <Table
            caption='Báo cáo'
            layout='auto'
            hover={false}
          >
            <Table.Head>
              <Table.Row>
                <Table.ColHeader id="name">Tên</Table.ColHeader>
                <Table.ColHeader id="percent">Tỷ lệ chủ đề được đánh dấu hoàn thành</Table.ColHeader>
                <Table.ColHeader id="Courses">Số khóa học có chủ đề đăng ký</Table.ColHeader>
                <Table.ColHeader id="Topics">Số chủ đề đăng ký</Table.ColHeader>
                <Table.ColHeader id="Topics">Số chủ đề đăng ký dạy học trực tuyến</Table.ColHeader>
                <Table.ColHeader id="Topics">Tỷ lệ chủ đề đánh dấu dạy học trực tuyến</Table.ColHeader>
                <Table.ColHeader id="result_tot">Tỷ lệ chủ đề ở mức tốt</Table.ColHeader>
                <Table.ColHeader id="result_dat">Tỷ lệ chủ đề ở mức đạt</Table.ColHeader>
                <Table.ColHeader id="result_dat">Tỷ lệ chủ đề ở mức chưa tốt</Table.ColHeader>
                <Table.ColHeader id="result_dat">Tỷ lệ chủ đề ở mức chưa xếp loại</Table.ColHeader>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {dataList.map((item) => renderProgress(item))}
            </Table.Body>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default ReportPage;
