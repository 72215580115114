import { Select, Tag } from "@instructure/ui";
import "moment/min/locales";
import React, { useEffect, useState } from "react";

const MultipleSelect = ({
  dataSource,
  selectedIds = [],
  setSelectedIds = () => {},
  valueField = "id",
  textField = "label",
  title = "",
}) => {
  const [data, setData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isShowingOptions, setIsShowingOptions] = useState(false);

  useEffect(() => {
    setData(dataSource);
  }, [dataSource]);

  const filterOptions = (value) => {
    const result = data.filter((option) =>
      option?.[textField]?.toLowerCase?.()?.startsWith(value.toLowerCase())
    );
    return result;
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    const newOptions = filterOptions(value);

    setInputValue(value);
    setData(newOptions);
    setIsShowingOptions(true);
  };

  const handleHideOptions = (e) => {
    setIsShowingOptions(false);
  };

  const handleSelectOption = (event, { id }) => {
    const option = getOptionById(id);
    if (!option) return; // prevent selecting of empty option

    setSelectedIds([...selectedIds, id]);
    setData(filterOptions(""));
    setInputValue("");
    setIsShowingOptions(false);
  };

  const getOptionById = (inputId) => {
    return data.find(({ id }) => id === inputId);
  };

  const renderTags = () => {
    return selectedIds.map((id, index) => (
      <Tag
        dismissible
        key={id}
        title={`Remove ${getOptionById(id)?.[textField]}`}
        text={getOptionById(id)?.[textField]}
        margin={index > 0 ? "xxx-small 0 xxx-small xx-small" : "xxx-small 0"}
        onClick={(e) => dismissTag(e, id)}
      />
    ));
  };

  const dismissTag = (e, tag) => {
    // prevent closing of list
    e.stopPropagation();
    e.preventDefault();

    const newSelection = selectedIds.filter((id) => id !== tag);
    // this.setState(
    //   {
    //     selectedOptionId: newSelection,
    //     highlightedOptionId: null,
    //   },
    //   () => {
    //     this.inputRef.focus();
    //   }
    // );

    setSelectedIds(newSelection);
  };

  return (
    <div>
      <Select
        renderLabel={title}
        isShowingOptions={isShowingOptions}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onRequestShowOptions={() => setIsShowingOptions(true)}
        onRequestHideOptions={handleHideOptions}
        onRequestSelectOption={handleSelectOption}
        renderBeforeInput={selectedIds?.length > 0 ? renderTags() : null}
      >
        {data?.length > 0 ? (
          data.map((option, index) => {
            if (selectedIds.indexOf(option?.[valueField]) === -1) {
              return (
                <Select.Option
                  id={option?.[valueField]}
                  key={option?.[valueField]}
                >
                  {option?.[textField]}
                </Select.Option>
              );
            }
          })
        ) : (
          <Select.Option id="empty-option" key="empty-option">
            ---
          </Select.Option>
        )}
      </Select>
    </div>
  );
};

export default MultipleSelect;
