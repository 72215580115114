import { Select } from "@instructure/ui";
import { useEffect, useState } from "react";

const SingleSelect = ({
  labelName,
  dataSource,
  className,
  setSelectedValue,
  selectedValue,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [isShowingOptions, setIsShowingOptions] = useState(false);
  const [highlightedOptionId, setHighlightedOptionId] = useState(null);
  const [selectedOptionId, setSelectedOptionId] = useState(null);

  const getOptionById = (id) => {
    return dataSource.find((x) => x.id === id);
  };

  const handleHideOptions = (event) => {
    const option = getOptionById(selectedOptionId)?.label;

    setIsShowingOptions(false);
    setHighlightedOptionId(null);
    setInputValue(selectedOptionId ? option : "");
  };

  const handleHighlightOption = (event, { id }) => {
    event.persist();
    const option = getOptionById(id).label;

    setHighlightedOptionId(id);
    setInputValue(event.type === "keydown" ? option : inputValue);
  };

  const handleSelectOption = (event, { id }) => {
    const option = getOptionById(id)?.label;

    setSelectedOptionId(id);
    setSelectedValue(id);
    setInputValue(option);
    setIsShowingOptions(false);
  };

  return (
    <div className={className}>
      <Select
        renderLabel={labelName}
        assistiveText="Use arrow keys to navigate options."
        inputValue={inputValue}
        isShowingOptions={isShowingOptions}
        onBlur={() => setHighlightedOptionId(null)}
        onRequestShowOptions={() => setIsShowingOptions(true)}
        onRequestHideOptions={handleHideOptions}
        onRequestHighlightOption={handleHighlightOption}
        onRequestSelectOption={handleSelectOption}
      >
        {dataSource.map((option) => {
          return (
            <Select.Option
              id={option.id}
              key={option.id}
              isHighlighted={option.id === highlightedOptionId}
              isSelected={option.id === selectedOptionId}
            >
              {option.label}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
};

export default SingleSelect;
