import { get, post, put, remove } from "./APICaller";

const BASE_URL = process.env.REACT_APP_BE_URL;

export const getLmsBluePrintCourse = (enrollment_term_id) => {
  return get(
    `${BASE_URL}/lms/blueprint-courses?enrollment_term_id=${enrollment_term_id}`
  );
};

export const getBluePrintCourse = (canvas_course_id) => {
  return get(
    `${BASE_URL}/blueprint-courses?filters[canvas_course_id]=${canvas_course_id}`
  );
};

export const getReport = () => {
  return get(
    `${BASE_URL}/report`
  );
};

export const deleteTopic = (id, course_id) => {
  return remove(`${BASE_URL}/blueprint-courses/${course_id}/topics/${id}`);
};

export const createStrapiBluePrintCourse = (params) => {
  return post(`${BASE_URL}/blueprint-courses`, params);
};

export const createTopic = (params) => {
  return post(`${BASE_URL}/topics`, params);
};

export const updateTopic = (id, params) => {
  return put(`${BASE_URL}/topics/${id}`, params);
};

export const getTopics = () => {
  return get(`${BASE_URL}/topics?populate=*`);
};

export const updateTopicComplete = (id, params) => {
  return post(`${BASE_URL}/topic/${id}/complete`, params);
};

export const getInstructors = (lms_blueprint_course_id) => {
  return get(
    `${BASE_URL}/lms/blueprint-courses/${lms_blueprint_course_id}/enrollments`
  );
};

export const getUserInfo = () => {
  return get(`${BASE_URL}/users/me?populate=*`);
};

export const getLmsRootAccountId = () => {
  return get(`${BASE_URL}/lms/root-account`);
};

export const getTerms = (root_account_id) => {
  return get(`${BASE_URL}/lms/accounts/${root_account_id}/terms`);
};

export const getTopicsInBluePrintCourse = (course_id) => {
  return get(`${BASE_URL}/blueprint-courses/${course_id}/topics?populate=*`);
};

export const getListModulesAPI = (lms_blueprint_course_id) => {
  return get(
    `${BASE_URL}/lms/blueprint-courses/${lms_blueprint_course_id}/modules`
  );
};

export const getCurriculaAPI = (course_id) => {
  return get(
    `${BASE_URL}/blueprint-courses/${course_id}/curricula?populate=*`
  );
};

export const createContentAPI = (course_id, curricula_id, params) => {
  return post(
    `${BASE_URL}/blueprint-courses/${course_id}/curricula/${curricula_id}/contents`,
    params
  );
};

export const updateContentAPI = (course_id, curricula_id, content_id, params) => {
  return put(
    `${BASE_URL}/blueprint-courses/${course_id}/curricula/${curricula_id}/contents/${content_id}`,
    params
  );
};

export const deleteContentAPI = (course_id, curricula_id, content_id) => {
  return remove(
    `${BASE_URL}/blueprint-courses/${course_id}/curricula/${curricula_id}/contents/${content_id}`
  );
};

export const getContentsAPI = (course_id, curricula_id) => {
  return get(
    `${BASE_URL}/blueprint-courses/${course_id}/curricula/${curricula_id}/contents`
  );
};