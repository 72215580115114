import { Navigation, Drilldown, Options } from "@instructure/ui";
import React, { useState } from "react";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";

const Sidebar = () => {
  const navigate = useNavigate();
  const [highlighted, setHighlighted] = useState(0);
  const [selected, setSelected] = useState(0);

  const [dataList, setDataList] = useState([
    {
      pageName: "Đăng ký chủ đề",
      key: "module-page",
      url: "/module-page",
    },
    {
      pageName: "Xem báo cáo",
      key: "module-page",
      url: "/report-page",
    },
  ]);

  const handleKeyDown = (event) => {
    let index = highlighted;

    if (event.keyCode === 40 && highlighted < this.props.options.length - 1) {
      // down arrow
      event.preventDefault();
      index = highlighted + 1;
    } else if (event.keyCode === 38 && highlighted > 0) {
      // up arrow
      event.preventDefault();
      index = highlighted - 1;
    } else if (event.keyCode === 13 && highlighted > -1) {
      // enter
      setSelected(index);
    }
    setHighlighted(index);
  };

  const handleFocus = (event, index) => {
    setHighlighted(index);
  };

  const handleMouseOver = (event, index) => {
    setHighlighted(index);
  };

  const handleClick = (event, index, url) => {
    setSelected(index);
    navigate(url);
  };

  return (
    <div className={`h-screen pt-12`}>
      <Options
        onKeyDown={handleKeyDown}
        onMouseOut={(e) => setHighlighted(-1)}
        tabIndex="0"
      >
        {dataList.map((option, index) => {
          let variant = "default";
          if (highlighted === index) {
            variant = "highlighted";
          } else if (selected === index) {
            variant = "highlighted";
          }
          return (
            <Options.Item
              key={option.key}
              variant={variant}
              tabIndex="-1"
              onMouseOver={(e) => handleMouseOver(e, index)}
              onFocus={(e) => handleFocus(e, index)}
              onClick={(e) => handleClick(e, index, option.url)}
            >
              {option.pageName}
            </Options.Item>
          );
        })}
      </Options>
    </div>
  );
};

export default Sidebar;
