import {
  InstUISettingsProvider,
  View,
  TreeBrowser,
  TextInput,
  IconButton,
  IconXSolid,
  IconCheckSolid,
  IconPlusLine,
  IconTrashLine,
  Button,
  IconEditLine,
} from "@instructure/ui";
import { useEffect, useState } from "react";
import { getCurriculaAPI, getContentsAPI, createContentAPI, deleteContentAPI, updateContentAPI } from "../../../../utils/API";

const Curricula = ({ selectedBluePrintCourse }) => {
  const [expanded, setExpanded] = useState({});
  const [edited, setEdited] = useState(false);
  const [inputEdit, setInputEdit] = useState({});
  const [hoveredLine, setHoveredLine] = useState(null);
  const [curriculum, setCurriculum] = useState(null);
  const [contents, setContents] = useState([]);
  const [textInput, setTextInput] = useState({});
  const [textChangeInput, setTextChangeInput] = useState({});
  const [isChangeInput, setIsChangeInput] = useState({});
  const [collections, setCollections] = useState({
    0: {
      id: 0,
      name: "",
      collections: []
    }
  });

  useEffect(() => {
    getContents();
  }, [selectedBluePrintCourse]);

  useEffect(() => {
    processContent();
  }, [collections]);

  useEffect(() => {
    processContent();
  }, [contents]);

  useEffect(() => {
    processContent();
  }, [edited]);

  const getContents = async () => {
    const cur = await getCurriculaAPI(selectedBluePrintCourse?.blueprint_course_id);
    setCurriculum(cur.data)
    const cont = await getContentsAPI(selectedBluePrintCourse?.blueprint_course_id, cur.data.id);
    setContents(cont.data)
  }

  const processContent = async () => {
    const collect = {};

    // Tạo đối tượng thư mục từ mảng dữ liệu
    contents.forEach((item) => {
      const { id, name } = item;
      if (id) {
        collect[id] = {
          id,
          name,
          collections: [],
          descriptor: "",
          renderAfterItems: renderNode(id),
          onKeyDown: () => {}
        };
      }
    });

    // Duyệt lại mảng dữ liệu để thêm thông tin thư mục cha và con
    contents.forEach((item) => {
      const { id, parent_content_ids } = item;
      if (parent_content_ids) {
        const parentIdArr = parent_content_ids.split("/");

        parentIdArr.forEach((parentId) => {
          if (collect[parentId]) {
            collect[parentId].collections.push(id);
          }
        });

        if (collect[id]) {
          collect[
            id
          ].descriptor = `chứa ${contents.reduce((count, content) => {
            if (content.parent_content_ids.split('/').includes(`${id}`))
              return count + 1
            else
              return count
          }, 0)} nội dung`;
        }
      }
    });

    const childOfRoot = [];

    // Lặp qua các phần tử trong đối tượng data để lấy danh sách tất cả các collections
    for (const key in collect) {
      if (collect.hasOwnProperty(key)) {
        const element = collect[key];
        childOfRoot.push(...element.collections);
      }
    }

    // Lọc các ID không nằm trong mảng collections và không phải là 0
    const filteredIds = Object.keys(collect)
      .filter((id) => {
        if (!isNaN(id) && parseInt(id) !== 0) {
          for (const key in collect) {
            if (collect[key].collections.includes(parseInt(id))) {
              return false;
            }
          }
          return true;
        }
        return false;
      })
      .map((id) => parseInt(id));

    collect[0] = {
      id: 0,
      name:  selectedBluePrintCourse?.label,
      collections: filteredIds,
      descriptor: "",
      renderAfterItems: renderNode(0),
    };
    setCollections(collect);
  };

  const handleSubmitInput = async (collectionId) => {
    let parent_content_ids = ""
    if (typeof contents[collectionId]?.parent_content_ids !== 'undefined') {
      parent_content_ids = collectionId == "0" ? `${collectionId}` : `${contents[collectionId]?.parent_content_ids}/${collectionId}`
    } else {
      parent_content_ids = `${collectionId}`
    }
   
    const cont = await createContentAPI(selectedBluePrintCourse?.blueprint_course_id, curriculum.id, {
      name: textInput[collectionId].value,
      parent_content_ids: parent_content_ids
    })

    let data = [...contents]
    data.push(cont.data)
    setContents(data);
  }

  const renderInput = (collectionId) => {
    const isExpanded = expanded[collectionId] || false;

    if (isExpanded) {
      return (
        <InstUISettingsProvider
          theme={
            hoveredLine === "renderAfter"
              ? {
                  componentOverrides: {
                    View: {
                      focusColorInfo: "white",
                    },
                    TextInput: {
                      focusOutlineColor: "white",
                    },
                  },
                }
              : undefined
          }
        >
          <View
            as="div"
            padding="xx-small"
            onFocus={(e) => e.stopPropagation()}
            onClick={(e) => e.stopPropagation()}
            onMouseEnter={() => setHoveredLine("renderAfter")}
            onMouseLeave={() => setHoveredLine(null)}
          >
            {!expanded[collectionId] && (
              <View
                as="div"
                display="inline-block"
                onClick={(e) => {
                  e.stopPropagation();
                  setExpand(collectionId, true);
                }}
                onKeyDown={(e) => {
                  if (e.code === 'Space' || e.code === "Enter") {
                    e.preventDefault();
                    setExpand(collectionId, true);
                  }
                }}
                tabIndex={0}
              >
                Thêm nội dung mới
              </View>
            )}
            {expanded[collectionId] && (
              <>
                <TextInput
                  placeholder="Nhập tên nội dung mới"
                  display="inline-block"
                  width="12rem"
                  renderLabel=""
                  onChange={(e, value) => {
                    e.stopPropagation();
                    const data = {...textInput}
                    data[collectionId] = {
                      value: value
                    }
                    setTextInput(data);
                  }}
                  onKeyDown={(e) => {
                    e.stopPropagation()
                    if (e.code === "Enter") {
                      e.preventDefault();
                      handleSubmitInput(collectionId)
                      setExpand(collectionId, false);
                    }
                  }}
                />
                <IconButton
                  screenReaderLabel="Hủy"
                  onClick={(e) => setExpand(collectionId, false)}
                  onKeyDown={(e) => {
                    if ( e.code === 'Space' || e.code === "Enter") {
                      e.preventDefault();
                      setExpand(collectionId, false);
                    }
                  }}
                  margin="0 0 0 small"
                >
                  <IconXSolid />
                </IconButton>
                <IconButton
                  screenReaderLabel="Thêm nội dung mới"
                  onClick={(e) => {
                    handleSubmitInput(collectionId)
                    setExpand(collectionId, false)
                  }}
                  onKeyDown={(e) => {
                    if (e.code === 'Space' || e.code === "Enter") {
                      e.preventDefault();
                      setExpand(collectionId, false);
                    }
                  }}
                  margin="0 0 0 small"
                >
                  <IconCheckSolid />
                </IconButton>
              </>
            )}
          </View>
        </InstUISettingsProvider>
      );
    }

    return (
      <View
        as="div"
        onClick={(e) => {
          e.stopPropagation();
          setExpand(collectionId, true);
        }}
        onKeyDown={(e) => {
          if (e.code === 'Space' || e.code === "Enter") {
            e.preventDefault();
            setExpand(collectionId, true);
          }
        }}
        tabIndex={0}
      >
        <IconPlusLine /> Thêm nội dung mới
      </View>
    );
  };

  const setExpand = (collectionId, expanded) => {
    setExpanded((prevState) => ({
      ...prevState,
      [collectionId]: expanded,
    }));
  };

  const renderNode = (collectionId) => {
    return (
      <TreeBrowser.Node
        onClick={(e) => {
          setExpand(collectionId, !expanded[collectionId]);
        }}
        onKeyDown={(e) => {
          if ( e.code === 'Space' || e.code === "Enter") {
            e.preventDefault();
            setExpand(collectionId, !expanded[collectionId]);
          }
        }}
        size="small"
      >
        {renderInput(collectionId)}
      </TreeBrowser.Node>
    );
  };

  const handleRemoveNode = async (e, collectionId) => {
    if (window.confirm("Bạn có muốn xóa nội dung này không?")) {
      const res = await deleteContentAPI(selectedBluePrintCourse.blueprint_course_id, curriculum.id, collectionId)
      let data = [...contents]
      data.splice(data.findIndex(item => item.id === res.data?.id), 1)
      setContents(data);
    }
  }

  const handleSubmitInputChangeName = async (collectionId) => {
    const res = await updateContentAPI(selectedBluePrintCourse.blueprint_course_id, curriculum.id, collectionId, {
      name: textChangeInput[collectionId]
    })

    let data = [...contents]
    const index = data.findIndex(item => item.id === res.data?.id);
    data[index].name = res.data.name;
    setContents(data);
    setIsChangeInput((prevState) => ({...prevState, [collectionId]: !isChangeInput[collectionId]}))
  }

  const handleOnChageUpdateNode = (event, value, collectionId) => {
    event.stopPropagation();
    setTextChangeInput((prevState) => ({
      ...prevState, 
      [collectionId]: value})
    );
  }

  const defaultExpanded = Object.keys(collections).map(Number);

  return (
    <TreeBrowser
      selectionType="single"
      size="large"
      defaultExpanded={defaultExpanded}
      collections={collections}
      showRootCollection={true}
      rootId={0}
      onCollectionToggle={()=>{}}
      collectionIcon={""}
      collectionIconExpanded={""}
      renderContent={(props)=> {
        if (props.level > 1) {
          return <div style={{padding: '0.6rem 0 0.6rem 1rem', display: 'flex'}}>
            <span style={{flex: 'auto'}}>
              <span>{props.name}</span>
              <span style={{fontSize: "small", display: "block"}}>{props.descriptor}</span>
            </span>
            
            <span style={{float: "right", alignSelf: 'center', marginRight: "5px"}} hidden={!isChangeInput[props.id]}>
              <TextInput
                placeholder="Nhập tên mới"
                onChange={(event, value) => handleOnChageUpdateNode(event, value, props.id)}
                onKeyDown={(e) => {
                  e.stopPropagation()
                  if (e.code === "Enter") {
                    e.preventDefault();
                    handleSubmitInputChangeName(props.id)
                  }
                }}
              />
            </span>
            <span style={{float: "right", alignSelf: 'center', marginRight: "5px"}}>
              <Button renderIcon={IconEditLine} color="primary-inverse" onClick={ (e) => setIsChangeInput((prevState) => ({...prevState, [props.id]: !isChangeInput[props.id]}))} size="small"></Button>
            </span>
            <span style={{float: "right", alignSelf: 'center'}}>
              <Button renderIcon={IconTrashLine} color="primary-inverse" onClick={ (e) => handleRemoveNode(e, props.id)} size="small"></Button>
            </span>
          </div>
        }
        return <div style={{padding: '0.6rem 0 0.6rem 1rem'}}>{props.name}</div>
      }}
    />
  );
};

export default Curricula;
