import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getUserInfo } from "../../../utils/API";

const Auth = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  useEffect(() => {
    onLoggedin();
  });

  const onLoggedin = async () => {
    const jwt = searchParams.get("jwt");
    if (jwt) {
      localStorage.setItem("token", jwt);

      try {
        const res = await getUserInfo();

        if (res.status === 200) {
          localStorage.setItem("user", JSON.stringify(res.data));
          navigate(`/module-page`);
        }
      } catch (error) {}
    } else {
      navigate(`/pages/403`);
    }
  };
};

export default Auth;
